$primary-color: #000;
$secondary-color: #364C61;
$text-color: #939598;
$text-color-1: #000;
$text-color-2: #2A3F54;
$text-color-3: #8D939B;
$text-disabled: #ACB2BA;
$link-color: #F2F1FF;
$link-color-1: #F8F8F8;
$grey-1: #ACB2BA;
$grey-2: #D1D1D1;
$grey-3: #F2F2F2;
$grey-4: #F5F5F5;
$grey-5: #F1F1F1;
$grey-6: #f5f4f7;
$white-color: white;
$status-green: #1BD365;
$approver-green: #50CE6C;
$approver-grey: #D5D9DD;
$status-red: #FF3C3C;
$status-orange: #B72121;
$status-blue: #5582AE;
$border-color: #EDEDED;
$border-color2: #b6b6b4;
$text-primary: #424242;
$text-color-4: #808081;
$grey-7: #e7e5e5;
$grey-8: #dedfe9;
$grey-9: #8c919a;
$grey-10: #00000026;
$chart-deactivate-color: #8884d8;
$black: #000;
$tableRow: rgba(0, 0, 0, .04);
$list-active: #F2F1FF;