@import "../../style/sass/main.scss";

// 
.MuiInputBase-formControl-custom {
    .MuiInputBase-formControl {
        border-radius: 0.375rem;
    }
    label.MuiFormLabel-root  {
        padding: 0 0.7rem;
        font-family: $fontFamily !important;
        color: $text-color !important;
        transform: translate(0.875rem,0.90rem) scale(1);
        -webkit-transform: translate(0.875rem,0.90rem) scale(1);
        -moz-transform: translate(.875rem,.90rem) scale(1);
        -ms-transform: translate(.875rem,.90rem) scale(1);
    }
    label.MuiInputLabel-shrink {
        font-size: 1rem;
        transform: translate(0.875rem, -0.65rem) scale(1);
        -webkit-transform:translate(0.875rem, -0.65rem) scale(1);
        -moz-transform: translate(0.875rem, -0.65rem) scale(1);
        -ms-transform:translate(0.875rem, -0.65rem) scale(1);
    }

    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.15) !important;
        border-width: 0.125rem !important;
        legend {
            font-size: 1.2rem;
            margin-left: 0.25rem;
        }
    }
    .MuiInputBase-input  {
        height: 3.2rem;
        line-height: 3.2rem;
        padding: 0 1.6rem;
    }

    .Mui-disabled {
        background-color: $grey-6;
        color: $text-disabled !important;
    }
}

.MuiInputBase-formControl-custom.search-listing-toolbar {
    .MuiInputBase-input  {
        padding: 0 1.6rem 0 0;
    }
}

.endAdornment-background {
    .MuiInputBase-formControl {
        padding-right: 0;

        .endAdornmentIcon {
            background: $grey-4;
            width: 3.75rem;
            height: 3.2rem;
            line-height: 3.2rem;
            border-top-right-radius: 0.375rem;
            border-bottom-right-radius: 0.375rem;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            svg {
                color: $text-color-3;
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}