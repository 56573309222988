.tablegrid {

    .k-grid.k-widget,
    .k-grid.k-grid-md,
    .k-table-md {
        font-size: 1.1rem !important;

        .k-column-title {
            font-size: 1.25rem !important;
        }

        .k-pager-info {
            display: block !important;
        }
    }

    .tablegrid-status {
        @include flexCenter;
    }

    .tablegrid-status-inprogress,
    .tablegrid-status-executing,
    .tablegrid-status-executed {
        font-size: 12px;
        line-height: 13px;
        padding: 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.25rem;
        text-align: center;
        border-radius: 5px;
    }

    .tablegrid-status-inprogress {
        border: 2px solid $status-orange;
        color: $status-orange;
        background: #f7e3e3;

    }

    .tablegrid-status-executed {
        border: 2px solid $status-green;
        color: $status-green;
        background: #edf9ed;
    }

    .tablegrid-status-executing {
        border: 2px solid $status-blue;
        color: $status-blue;
        background: #e9f0f7;
    }

    .tablegrid-status-active,
    .tablegrid-status-inactive {
        border-radius: 0.625rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 15px 23px;
        @include flexCenter;
        width: 6.25rem;
    }

    .tablegrid-status-active {
        border: 2px solid $status-green;
        color: $status-green;
    }

    .tablegrid-status-inactive {
        border: 2px solid $status-red;
        color: $status-red;
    }

    .tablegrid-switch {
        text-align: center;
    }

    .k-grid-pager {
        font-size: 14px;
        border-color: transparent;
    }

    .tablegrid-column-sort {
        @include cursorPointer;
    }

    .k-grid-header {
        padding: 0;

        .k-header {
            overflow: visible;
            white-space: normal;
        }
    }

    .k-grid-container {
        .k-grid-content {
            overflow-y: hidden;
        }
    }
    tr {
        th {
            font-family: $fontFamily;
            font-weight: $semiBold;
            font-size: 1.25rem !important;
            line-height: 1.5rem;
            color: $black;
            background: $primary;
            padding: 1rem 1.3125rem !important;
            border: 1px solid #ACB2BA;
            text-align: center;
            vertical-align: middle !important;
            white-space: inherit;
            word-break: break-word;
            span.th-flex {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                padding-right: 1.875rem;
                position: relative;
                i {
                    position: absolute;
                    right: 0.625rem;
                }
            }
        }
       td {
            color: $black;
            font-size: 1.13rem !important;
            line-height: 1.25rem;
            padding: 1rem 1.3125rem !important;
        }
    }

    .tablegrid-heading,
    .k-header {
        .k-icon {
            color: $white-color !important;
        }
    }
}

.k-pager-numbers .k-link {
    color: $primary-color !important;

    &:hover {
        background-color: rgba(41, 38, 102, 0.08) !important;
    }
}

.k-link.k-selected {
    color: $primary-color !important;
    background-color: rgba(41, 38, 102, 0.2) !important;
    z-index: inherit;


}
.icon-container {
    display: inline-block;
  }
  
  .custom-icon {
    font-size: 24px;
  }