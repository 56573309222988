// Font weights
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$zindex-dialog: 999;
$zindex: 9999;
$zindex-toaster: 99999;
$zindex-chartTooltip: 9999999999999;
$fontFamily: "Montserrat";

// Logo Styling
.logo-3,
.logo-sc {
  font-size: 8rem;
  line-height: 13rem;
  font-weight: $bold;
}

.confirmation-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 30px;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1000;
}
 
.popup-content {
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
  color: #333;
}
.buttons-container {
  display: flex;
  justify-content: center;
}
 
.buttons-container button {
  padding: 10px 20px;
  margin: 0 10px;
  border: 2px solid #555;
  border-radius: 8px;
  cursor: pointer;
}
 
.buttons-container button:first-child {
  background: #fff;
  color: #000;
}
 
.buttons-container button:last-child {
  background: #E94F1C;
  color: #fff;
}
 
.edit-confirmation-popup {
  position: absolute;
  // top: 100px;
  right: 0;
  bottom: 0 !important;
  transform: translate(0, 0);
  background: white;
  // padding: 20px;
  width: 400px;
  height: 100vh;
  border-radius: 3px;
  // text-align: center;
  z-index: 9999 !important;
 
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // margin-top: 5px;
  // margin-bottom: 5px;
  overflow-y: scroll !important;

}
 
.edit-popup-content {
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
  color: #333;

} 
.input-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;

}
 .add_edit_button{

  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  font-size: 10px;
  position: sticky;
  border-top: 1px solid #dbd4d4;
  bottom: 0px;

 }
 .add_edit_button button:first-child{
  outline: none;
  border: none;
  background-color: #E94F1C;
  border-radius: 2px;
padding: 6px 12px;
margin-right: 10px;
color: white;
border-radius: 10px;

 }
 .add_edit_button button:last-child{
  outline: none;
  border: none;
  border: 1px solid black;
  border-radius: 2px;
  padding: 5px 11px;
  margin-right: 10px;
  background-color: white;
  color: black;
  border-radius: 10px;



 }
.input-field {
  flex: 0 0 100%;
  margin-bottom: 10px;
}
 
.edit-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}
 
.edit-buttons-container button {
  padding: 10px 20px;
  border: 2px solid #555;
  border-radius: 8px;
  cursor: pointer;
}
 
.edit-buttons-container button:first-child {
  background: #fff;
  color: #000;
}
 
.edit-buttons-container button:last-child {
  background: #e94f1c;
  color: #fff;
}

.logo-3 {
  color: $primary-color;
}

.logo-sc {
  color: $secondary-color;
}

.remove-underline {
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.common-heading {
  color: $primary-color;
  font-size: 1.25rem;
  font-weight: $semiBold;
  line-height: 1.5rem;
}

.section-heading {
  font-weight: $bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $primary-color;
}

.section-subheading {
  font-weight: $semiBold;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $text-disabled;
}

.grey-heading-500,
.grey-heading-600 {
  color: $grey-2;
  font-size: 1rem;
  line-height: 1.25rem;
}

.grey-heading-500 {
  font-weight: $medium;
}

.grey-heading-600 {
  font-weight: $semiBold;
}

.section-breadcrumbs {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: $regular;

  .active {
    color: $primary-color;
  }
}

.section-breadcrumbs-a {
  color: $text-disabled;

  &:hover {
    color: $primary-color;
  }
}

// Mui Text Field Global Styling
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: $grey-1 !important;
}

label.MuiInputLabel-root,
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  color: $grey-1;
  z-index: 0;
}

.primary-button {
  background: #E94F1C;
  color: $white-color;
  border-radius: 0.625rem;
  padding: 0.9375rem 1.1875rem;
  font-size: 1rem;
  font-weight: $semiBold;
  line-height: 1.25rem;

  &:hover {
    color: $white-color;
  }
}

.secondary-button {
  background: $link-color-1;
  color: $primary-color;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 10px;
  padding: 1rem;
  font-weight: $semiBold;
}

.cancel-button {
  border: 1px solid $primary-color;
  padding: 0.9375rem 1.375rem;
  color: $primary-color;
  line-height: 1.25rem;
  font-weight: $semiBold;
  border-radius: 0.625rem;
}

.ml-20 {
  margin-left: 1.25rem !important; //20px
}

.ml-10 {
  margin-left: 0.625rem !important; //10px
}
.ml-15 {
  margin-left: 0.938rem !important; //15px
  font-weight: 600 !important;
  background-color: #EDEDED !important;
  color: black !important;
}

.ml-5 {
  margin-left: 0.3125rem !important; //5px
}

// Highlight the search field after Click the "GO" button
.highlight-body {
  background-color: #000 !important;
  // border: 0.1px solid #000 !important; 
  border-radius: 5px;
}

.highlight-border {
  border: 0.1px solid #000 !important; 
  // border: 0.1px solid #000 !important; 
  border-radius: 5px;
}

.ml-16 {	
  margin-inline-start: auto !important; //15px	
  font-weight: 600 !important;	
}

.mr-20 {
  margin-right: 1.25rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mr-5 {
  margin-right: 0.3125rem !important;
}

.mt-5 {
  margin-top: 0.3125rem !important;
}

.k-grid-header.k-header {
  color: $white-color;
  background: $primary-color;
  border: 1px solid $border-color;
  padding: 28px 21px;
}

.csv-upload-success-li {
  list-style: none;
  color: $status-green;
}

.admin-toolbar-search {
  .MuiInputBase-input.MuiOutlinedInput-input {
    line-height: 0.875rem;
    padding: 0rem;
    height: 3.2rem;
  }

}