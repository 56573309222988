.add_edit_active_inactive{
  display: flex;
  flex-direction: column;
}
.add_edit_lifecycle_status{
  color: gray;
  font-size: 10px;
  width: 50%;
  font-weight: 400;
}
.active-inactive-button{
  display: flex;
  justify-content: space-between;
}
.add_edit_active_button{
// background-color: green;
border: none;
outline: none;
// color: white;

  
    font-weight: 400;
    font-size: smaller;
    padding: 2px 5px;
    border-radius: 2px ;
    // margin-left: 10px;


}
.add_edit_inactive_button{
  // background-color: red;
  border: none;
  // color: white;
  outline: none;
  font-weight: 400;
  font-size: smaller;
  padding: 2px 5px;
  border-radius: 2px ;
  margin-left: 10px;
  


}
.like_sku_search_button{
  display: flex !important;
  justify-content: flex-end !important;
}
.select_sku_table_checkbox {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
    font-weight: 400;
    font-size: 12px;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
    font-weight: 800;
    font-size: 12px;
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    tr:hover {
      background-color: #ddd;
    }
  }

  input[type='checkbox'] {
    margin-right: 5px;
  }
}
