.client-listing {
    width: 100%;
    // padding: 1.125rem;
    padding: 0rem;
    // background: $grey-4;

    .client-listing-content {
        width: 100%;
        background: $white-color;
        padding: 1.25rem;
    }

    .client-listing-toolbar {
        margin-bottom: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .client-listing-toolbar-left,
    .client-listing-toolbar-right {
        display: flex;
        align-items: center;

        .client-listing-toolbar-right-csv-download {
            .search-listing-toolbar {
                .MuiInputLabel-outlined {
                    color: $primary-color;
                }
            }
        }
        .toolbar-search-btn {
            button {
                line-height: 0.875rem;
                padding: 0rem 1rem;
                height: 3.2rem;
                font-size: 1rem;
            }
        }
        .custom-search-form {
            display: flex;           
        }
    }
   

    .client-listing-toolbar-left {
        .toolbar-pagerows-select {
            .pagerows-select__control {
                min-height: 3.313rem;
                height: 3.313rem;
    
                .pagerows-select__value-container {
                    .pagerows-select__single-value {
                        color: $text-color-3;
                    }
                }
    
                .pagerows-select__indicators {
                    .pagerows-select__indicator {
                        padding: 0.5rem;
                    }
                }
            }
        }

        .client-listing-toolbar-left-refresh {
            color: $primary-color;
            background: $link-color-1;
            padding: 0;
            width: 3.313rem;
            height: 3.313rem;
            border-radius: 0.4375rem;
            @include flexCenter;
            @include cursorPointer;

            i {
                font-size: 1rem;
            }
        }

        .disabled-refresh {
            color: #84839a;
            background-color: #f3f3f3;
            cursor: auto !important;
        }
    }

    .mrg {
        margin-left: 0.938rem;
    }

}

.k-window {
    -webkit-border-radius: 1.25rem !important;
    border-radius: 1.25rem !important;
    font-size: 1rem;
}
.k-window-content.k-dialog-content {
    padding: 1rem 1.3rem;
    padding-top: 1rem!important;
}

.span1 {
    color: $primary-color;
    font-weight: bold;
    font-size: 1.25rem;
}

.k-btn1 {
    color: $border-color2;
    float: right;
    margin-top: -0.563rem;
    font-size: 1.7rem;
    position: relative;
    top: 0.313rem;
    cursor: pointer;
}

.border2 {
    margin: 1.25rem;
    border: 0.125rem dashed;
    border-radius: 0.625rem;
    color: $border-color2;
}

.div2 {
    margin: 1.25rem;
    text-align: center;
}

.k-span {
    color: #B6B6B4;
    font-size: 3.125rem;
}

.kh4 {
    color: $black;
    font-weight: bold;
    text-align: center;
}

.kp {
    color: #B6B6B4;
    font-weight: lighter;
    font-size: 0.875rem;
    text-align: center;
}
.date_format_text {
    b {
        font-weight: 700;
    }
}

  
