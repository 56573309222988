:root {
  --primary-color: #000;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dialogsize{
  margin-left: 120px !important;
}
.bootstrap_dialog{
  position: absolute !important;
  padding: 20px !important;
}
.draggable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;

.searchItem {	
  width: 100%;	
  height: fit-content;	
  font-size: 1.5rem;	
  margin-bottom: 10px;	
  
}

}
.draggable-item span {
  
    text-align: start;
    width: 70%;
    padding: 2px;
}

.advance-search-popup-container {
  /* width: 55%;
  position: absolute;
  z-index: 999;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  top: 60px;
  
  right: 14%; */
  position: absolute;
  z-index: 9;
  background-color: white;
  border: 1px solid lightgray;
  width: 80%;
  margin: 100px;
  padding: 20px;
  border-radius: 10px;
}
.advance-search-icon-container{
  display: flex;
  justify-content: space-between;
  padding: 15px;

}
.advance_search_table_dropdown{
  position: relative !important;
  z-index: 9999999 !important;
}

.advance-search-button-container {
  
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.advance-search-button-container-right{
  display: flex;
  justify-content: flex-end;
}
.advance-search-table-container{
  padding: 20px;
}
.advance-search-table{
  padding: 13px;
  width: 100%;
  border-collapse: collapse;
}

.advance-search-table th{
  text-align: center;
  border: 1px solid lightgray;
  font-size: 1.3rem;
  padding: 5px;
}
.advance-search-table td{
  text-align: center;
  border: 1px solid lightgray;
  font-size: 1.3rem;
  padding: 5px;

}
.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  padding: 16px;
 
  width: 300px;
  height: 300px;
}
.checkbox-container {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.checkbox-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: #ccc; 
  border-radius: 3px;
}

.checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: #000; 
}
.sort_column{
  /* background-color: #000 !important; */
}
.checkbox-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.k-grid,
.k-grid-header,
.k-header,
th.k-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-grid td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row>td,
.k-filter-row>th {
  border-color: #ACB2BA !important;
}



.checkbox-container input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}




.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    position: absolute;
    z-index: 999;
    left: 37%;
    top: 23%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    margin: 32px;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 600px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  color: var(--primary-color);
  font-weight: 600;
}
.input-select-wrapper {
  position: relative;
  color: var(--primary-color);
  
}

.input-select-icon {
  position: absolute;
  top: 50%;
  left: 66px; 
  transform: translateY(-50%);
}
.download-icon-mui{
  color: var(--primary-color);
  cursor: pointer;

}
td, th {
  text-align: center    !important;
}

td, th{
  text-align: center !important;
}
.hideshowborder{
  border: 2px solid #E94F1C !important;
}
.hideshowclose {
  font-size: 35px;
  font-weight: 300;
  cursor: pointer;
}
.item-text {
  margin-right: 100px; 
}
.custom-appbar {
  width: 100%;
}

