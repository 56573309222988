// App.scss
$primary: #EDEDED;
$grid-bg: #fff;

@import "~@progress/kendo-theme-default/dist/all.scss";

.upload-button {
  background-color: #03045e;
  font-weight: bold;
  font-size: 0.625rem;
  border-radius: 0.375rem;
  color: $white-color;
  margin-left: 5rem;
  padding: 0.5rem;
}

.dl-link {
  margin-top: 0.5625rem;
  margin-bottom: 0.5625rem;
  background-color: #f1f1f1;
  font-weight: bold;
  font-size: 0.625rem;
  border-radius: 0.375rem;
  color: #03045e;
  margin-left: 0.875rem;
  padding: 0.4375rem;
}

// .close-btn {
//   font-size: 1.25rem;
// }


.tenantId{
  font-size:1.125rem;
}

.cls-span {
  font-size: 3.125rem;
}

.snop-listing-input-box {
  width: 14.2rem;

  .forecast-snop-select {
    .MuiInputBase-root {
      background-color: $white-color;

      fieldset {
        border-color: $text-disabled !important;
      }
    }

    .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      bottom: 0rem !important;
      top: -0.46rem !important;
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
      height: 1.25rem;
      padding: 1rem 1rem;
    }
  }
}

.custom-k-dialog-buttongroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.75rem;
}
.download-template-button {
  margin-top: 0.563rem;
  margin-bottom: 0.563rem;
  background-color: $grey-5;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 0.375rem;
  color: $text-color-1;
  margin-left: 0.875rem;
  padding: .9375rem 1.1875rem;
  line-height: 1.25rem;
  border:none;
  .k-icon.k-i-download {
    font-size: 1rem;
  }
}

.upload-csv-button-dialog {
  background-color: $primary-color;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 0.375rem;
  color: white;
  margin-left: 5rem;
  padding:.9375rem 1.1875rem;
  line-height: 1.25rem;
  margin-right: 0.833rem;

  &:hover {
    background-color: $primary-color;
  }

  &:focus {
    background-color: $primary-color;
  }

  &:active {
    background-color: $primary-color;
  }
}

.dialog-error {
  display: flex;
  justify-content: center;
  font-style: normal;
}


// .tablegrid {
//   .table-style {
//      .k-grid-header {
//       .k-grid-header-wrap {
//         .column-sorting-icon {
//           position: relative;
//           vertical-align: middle !important;
//           padding: 0.75rem 1.5rem 0.75rem 0.75rem;
//           text-overflow: inherit;
//           white-space: inherit;
//           word-break: break-word;

//           i {
//             position: absolute;
//             right: 0rem;
//             font-size: 0.875rem;
//             top: 50%;
//             transform: translate(-50%, -50%);
//           }
//         }
//       }
//     }

//     .k-grid-content {
//       table {
//         tr {
//           td {
//             text-overflow: inherit;
//             white-space: inherit;
//             word-break: break-word;
//           }
//         }
//       }
//     }
//   }

//   .tablegrid_pagination {
//     .k-pager.k-widget {
//       background-color: transparent;
//       border: none;
//     }
//   }
// }