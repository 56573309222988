@import "../../style/sass//main.scss";

.kendo-customD-upload-dialog {
    .k-window-actions {
        margin: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: inherit;
        border-style: solid;
        border-width: 1px 0 0;
    }
    .upload-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

